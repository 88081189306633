import React from "react";
import ContactButton from "../../components/contactButton";
import HeaderDivideTextLeft from "../../components/headerDivideTextLeft";
import Layout from "../../components/layout";
import Row from "starkeydigital/React/Components/row";
import Accordion from "starkeydigital/React/Components/accordion";
import AnimateUp from "starkeydigital/React/Components/AnimateUp";
interface props {}
const SeniorLeaders: React.FC<props> = (props) => {
  return (
    <Layout
      titleText="Senior Leaders"
      titleText2=""
      slogan="We are very experienced coaches having worked with senior executives and professionals facing a
      wide range of challenges."
    >
      <HeaderDivideTextLeft
        style={{marginTop: "115px"}}
        column="col-md-10"
        header="Senior Leaders"
        text="Our coaches have worked at board level as well as with business teams and individuals. We understand and work with both the business and emotional influences on executive behaviour. We have coached leaders and aspiring leaders within global corporations, banks and professional service firms."
      />

      <Row>
        <div className="col mt-4">
          <ContactButton>Ready to work together?</ContactButton>
        </div>
      </Row>

      <Row style={{marginTop: "60px"}}>
        <div className="col">
          <Accordion>
            <AnimateUp>
              <Accordion.Item title="Why would you invest in Executive Coaching?">
                Helping a senior individual to realise their potential /
                Challenging and supporting those in leadership roles to lead in
                the most productive way / Improving individual performance /
                Helping handle difficult or important relationships /
                Accelerating change / Improving staff retention / Motivating
                staff / Transferring learning from developmental courses to the
                workplace
              </Accordion.Item>
            </AnimateUp>

            <AnimateUp>
              <Accordion.Item title="Who would benefit from Executive Coaching?">
                Those with unrealised potential / Those who are leading change
                or implementing change through others / Those who are
                encountering change in their organisational role or team / Those
                who have undertaken training and need to take that learning back
                to the workplace
              </Accordion.Item>
            </AnimateUp>

            <AnimateUp>
              <Accordion.Item title="When would Executive Coaching work for you?">
                When the individual’s potential is not being realised at work /
                At the point of change in role or responsibility / When an
                individual is asked to deal with change / In association with a
                course or other training
              </Accordion.Item>
            </AnimateUp>
            <AnimateUp>
              <Accordion.Item title="How would Executive Coaching work?">
                An agreed number of 1:1 sessions with the individual at their
                work place / Using the work that the individual is doing at the
                time / Working toward agreed goals / Challenging and supporting
                the individual in meeting the goals through the work that they
                do / Giving the individual tools to help them meet the goals
              </Accordion.Item>
            </AnimateUp>
          </Accordion>
        </div>
      </Row>
    </Layout>
  );
};
export default SeniorLeaders;
